<template>
  <div class="GradingCurve">
    <template v-if="$route.query.markType == 2"> <no-data> </no-data></template>
    <template v-else>
      <div class="search-box">
        <div>
          题目：
          <el-select v-model="webId" placeholder="请选择" @change="checkList">
            <el-option
              v-for="(item, index) in questionOrderList"
              :key="index"
              :label="item.label"
              :value="item.webId"
              :disabled="item.isChild > 0"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <template v-if="scoreList.length > 0">
        <div class="tips">注意：红色线为总体线！</div>
        <div id="myChart" class="my-chart"></div
      ></template>
      <no-data v-else></no-data>
      <div v-if="scoreList.length > 0" class="tips">
        注意：折线图数据点提示框可点击进入对应的阅卷抽查，点击列表勾选框可以显示相应教师的曲线。
      </div>

      <el-table
        v-if="scoreList.length > 0"
        :data="tableList"
        border
        style="width: 100%"
      >
        <el-table-column prop="name" label="姓名" align="center">
          <template slot-scope="scope">
            <div
              :class="{ 'is-click': scope.row.name != '总体' }"
              @click="showListName(scope.row.name)"
            >
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(v, i) in scoreList"
          :key="i"
          :prop="v"
          :label="v + '分'"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[v]"
              :class="{ 'is-click': scope.row.name != '总体' }"
              @click="showList(v, scope.row)"
            >
              {{ scope.row[v] }}
            </div>
            <template v-else>0</template>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import { getObj } from "@/core/api/exam/exam";
import { scoreCurve, getQuestionTeacher } from "@/core/api/exam/paperMonitor";
import { showSubject } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import { mapGetters } from "vuex";
// import { blob } from "stream/consumers";
// import { blob } from "stream/consumers";
export default {
  name: "GradingCurve",
  data() {
    return {
      questionNum: "",
      examPaperId: "",
      questionOrderList: [],
      paperList: [],
      tableList: [],
      scoreList: [],
      schoolList: [],
      schoolId: "",
      userInfo: {},
      webId: 0,
      nowQuestion: {},
    };
  },
  computed: {
    ...mapGetters(["GET_SCHOOL_LIST"]),
  },
  watch: {
    GET_SCHOOL_LIST: {
      deep: true,
      handler() {
        this.schoolList = this.GET_SCHOOL_LIST;
        if (this.schoolList.length > 0) {
          this.schoolId = "-1";
        }
      },
    },
  },
  created() {
    this.examPaperId = Number(this.$route.query.examPaperId);
    if (this.$route.query.markType == 2) {
      return;
    }
    this.getQuestionTeacher(1);
    this.getExamInfo();
    this.userInfo = getStore({ name: "userInfo" });
    this.schoolList = this.GET_SCHOOL_LIST;
    if (this.schoolList.length > 0) {
      this.schoolId = "-1";
    }
  },
  methods: {
    showListName(v) {
      let teacherId = "";
      this.teacherList.map((index) => {
        if (index.name == v) {
          teacherId = index.teacherId;
        }
      });
      let searchData = {
        questionNum: this.nowQuestion.value,
        teacherId: teacherId,
        score: "",
        webId: this.webId,
      };
      let data = this.$route.query;
      this.$router.push({
        name: "SamplePaperInspection",
        query: data,
        params: searchData,
      });
    },
    showList(v, row) {
      // console.log(this.teacherList[0].name);
      // console.log(row.name);
      let teacherId = "";
      this.teacherList.map((index) => {
        if (index.name == row.name) {
          teacherId = index.teacherId;
        }
      });
      // console.log(teacherId);
      if (!teacherId) {
        return;
      }
      let searchData = {
        questionNum: this.nowQuestion.value,
        teacherId: teacherId,
        score: v,
        webId: this.webId,
      };
      let data = this.$route.query;
      this.$router.push({
        name: "SamplePaperInspection",
        query: data,
        params: searchData,
      });
      // console.log(searchData);
      // let searchData={}
    },
    selectChange() {
      this.scoreCurve();
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        // this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },
    checkList() {
      this.nowQuestion = this.questionOrderList.find(
        (item) => item.webId == this.webId
      );
      this.scoreCurve();
    },
    checkSubject() {
      this.scoreCurve();
    },
    sortList(arr) {
      // 编写方法，实现冒泡
      // var arr = [29,45,51,68,72,97];
      //外层循环，控制趟数，每一次找到一个最大值
      for (var i = 0; i < arr.length - 1; i++) {
        // 内层循环,控制比较的次数，并且判断两个数的大小
        for (var j = 0; j < arr.length - 1 - i; j++) {
          // 白话解释：如果前面的数大，放到后面(当然是从小到大的冒泡排序)
          if (arr[j] > arr[j + 1]) {
            var temp = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = temp;
          }
        }
      }
      return arr;
    },
    scoreCurve() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.nowQuestion.value,
        type: this.nowQuestion.type,
        sheetDetailId: this.nowQuestion.sheetDetailId,
      };
      if (this.$route.query.examType == 1) {
        data.schoolId = this.schoolId || "-1";
      }
      if (this.userInfo.roleId != 6) {
        data.schoolId = getStore({ name: "school_id" });
      }

      scoreCurve(data).then((res) => {
        let scoreAllList = res.data.data;
        this.scoreAllList = JSON.parse(JSON.stringify(scoreAllList));
        let teacherIdList = [];
        let teacherList = [];
        let scoreList = [];
        // this.seriesList = [];
        // this.tableList = [];
        // 过滤出需要的老师id 分数列表
        scoreAllList.forEach((item) => {
          if (teacherIdList.indexOf(item.teacherId) == -1) {
            teacherIdList.push(item.teacherId);
            teacherList.push({
              teacherId: item.teacherId,
              name: item.teacherName,
            });
          }
          if (scoreList.indexOf(item.score) == -1) {
            scoreList.push(item.score);
          }
        });
        teacherIdList = this.sortList(teacherIdList);
        scoreList = this.sortList(scoreList);

        this.seriesList = [];
        this.tableList = [];
        this.teacherList = teacherList;
        // 组装分数列表
        teacherList.forEach((index) => {
          let data = {
            name: index.name,
            teacherId: index.teacherId,
            type: "line",
            data: [],
          };
          let colorArr = ["#F6BD16", "#FF8C68", "#5D7092"];
          if (data.teacherId == 0) {
            data.itemStyle = {
              normal: {
                color: "#FF0000", //改变折线点的颜色
                lineStyle: {
                  color: "#FF0000", //改变折线颜色
                },
              },
            };
          } else {
            data.itemStyle = {
              normal: {
                color: colorArr[data.teacherId % 3], //改变折线点的颜色
                lineStyle: {
                  color: colorArr[data.teacherId % 3], //改变折线颜色
                },
              },
            };
          }
          //
          let tableData = {
            name: index.name,
          };
          scoreList.forEach((i) => {
            try {
              scoreAllList.forEach((item) => {
                // console.log(item);
                // console.log(i);
                if (index.teacherId == item.teacherId) {
                  // data.keyVal = item;
                  if (i == item.score) {
                    data.data.push(Number((item.rate * 100).toFixed(2)));

                    data.allMsg = item;
                    tableData[i] = (item.rate * 100).toFixed(2) + "%";
                  }
                  // 如果当前老师没有当前分数 那么就添加个0分进去
                  let array = scoreAllList.filter(
                    (items) =>
                      index.teacherId == items.teacherId && i == items.score
                  );
                  if (array.length == 0) {
                    data.data.push(0);
                    throw new Error("End");
                  }
                }
              });
            } catch (e) {
              if (e.message != "End") throw e;
            }
          });

          this.seriesList.push(data);
          this.tableList.push(tableData);
        });
        // console.log(this.tableList);
        // console.log(this.seriesList);
        this.seriesList.reverse();
        this.tableList.reverse();
        this.scoreList = scoreList.map((item) => (item = item.toString()));
        // console.log();
        if (this.scoreList.length > 0) {
          this.$nextTick(() => {
            this.setLine();
          });
        }

        // console.log(this.seriesList);
        // console.log(teacherIdList);
        // console.log(scoreAllList);
        // console.log(this.seriesList);
        // console.log(this.scoreList);
      });
    },
    // 设置线条
    setLine() {
      let myChart = this.$echarts.init(
        document.getElementById("myChart"),
        null,
        { devicePixelRatio: 2.5 }
      );
      // 绘制图表
      myChart.setOption(
        {
          title: {
            text: "评分曲线",
            // textAlign: "right",
          },
          tooltip: {
            trigger: "item",
            // extraCssText: "width:300px;",
            formatter: (param) => {
              // console.log(param);
              let indexScore = this.scoreAllList.filter(
                (item) => item.teacherName == "总体" && item.score == param.name
              )[0];
              let indexScoreS = this.scoreAllList.filter(
                (item) =>
                  item.teacherName == param.seriesName &&
                  item.score == param.name
              )[0];
              // console.log(indexScoreS);
              let text = ` <div>分值：${param.name} 分</div>`;
              if (indexScoreS) {
                text += `<div>百分比：${param.value}%(${indexScoreS.scoreCount}/${indexScoreS.count})</div>`;
              }
              if (param.seriesName != "总体") {
                if (indexScore) {
                  text += `<div>总体：${(indexScore.rate * 100).toFixed(2)}%(${
                    indexScore.scoreCount
                  }/${indexScore.count})</div>`;
                }

                text += `<div>评分人：${param.seriesName}</div>`;
              } else {
                // text = "";
              }
              //自定义tooltip内容

              return text;
            },
          },
          legend: {
            itemGap: 30,
            type: "scroll",
            left: "center",
            width: "80%",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "8%",
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.scoreList,
            nameGap: 30, // x轴name与横坐标轴线的间距
            nameLocation: "middle", // x轴name处于x轴的什么位置
            nameTextStyle: {
              fontSize: 14,
              fontWeight: 600,
            },
            name: "分数",
          },
          yAxis: {
            type: "value",
            name: "百分比", //y轴上方的单位
            nameRotate: 90, // y轴name旋转90度 使其垂直
            nameGap: 50, // y轴name与横纵坐标轴线的间距
            nameLocation: "middle", // y轴name处于y轴的什么位置
            nameTextStyle: {
              fontSize: 14,
              fontWeight: 600,
            },
            axisLabel: {
              //y轴文字的配置
              formatter: "{value} %", //y轴的每一个刻度值后面加上‘%’号
            },
          },

          series: this.seriesList,
        },
        true
      );
    },
    getQuestionTeacher(type) {
      let data = {
        examPaperId: this.examPaperId,
        type: type,
      };
      getQuestionTeacher(data).then((res) => {
        if (type == 1) {
          this.questionOrderList = res.data.data.map((item, index) => {
            item.webId = index;
            return item;
          });
          // const index = this.questionOrderList.findIndex(
          //   (item) => item.isChild === 1
          // );
          // if (index === -1 || index > 1) {
          // index 大于1 说明分项给分没在第一位
          this.webId = this.questionOrderList[0].webId;

          this.nowQuestion = this.questionOrderList[0];
          // } else {
          //   // 如果有index不等于-1的则有分项给分
          //   this.webId = this.questionOrderList[1].webId;
          //   this.nowQuestion = this.questionOrderList[1];
          // }

          this.scoreCurve();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.GradingCurve {
  padding: 24px;
  .is-click {
    cursor: pointer;
    color: $primary-color;
  }
  ::v-deep .noData {
    height: 504px;
  }
  .my-chart {
    width: 100%;
    height: 280px;
    margin-bottom: 24px;
  }
  .tips {
    background: #f2f8fe;
    border: 1px dashed #2474ed;
    color: #737677;
    line-height: 14px;
    padding: 13px 12px;
    margin-bottom: 24px;
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
